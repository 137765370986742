<template>
    <div class="container">
      <!-- Table -->
      <div class="row justify-content-center">
        <div class="col">
            <el-form ref="form" :model="model" :show-message="false" label-width="auto" label-position="top" v-loading="loading">
                <div class="row">
                    <div class="col-md-3">
                    <label class="form-label required">所在区域</label>
                    <el-form-item prop="district" :rules="[{required:true}]">
                        <el-select v-model="model.district" placeholder="选择区域">
                            <el-option v-for="d in options.districts" :key="d" :label="d" :value="d"></el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                    <div class="col-md-4">
                    <label class="form-label required">企业名称</label>
                    <el-form-item prop="store_name" :rules="[{required:true}]">
                        <el-input v-model="model.store_name" placeholder="填写企业名称" required></el-input>
                    </el-form-item>
                    </div>
                    <div class="col-md-3">
                    <label class="form-label required">企业性质</label>
                    <el-form-item prop="gov_ent" :rules="[{required:true}]">
                        <el-select v-model="model.gov_ent" placeholder="选择企业性质">
                            <el-option label="央属国有企业" value="央属国有企业"></el-option>
                            <el-option label="省属国有企业" value="省属国有企业"></el-option>
                            <el-option label="市属国有企业" value="市属国有企业"></el-option>
                            <el-option label="区属国有企业" value="区属国有企业"></el-option>
                            <el-option label="其他国有企业" value="其他国有企业"></el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                    <div class="col-md-3" v-if="false">
                    <label class="form-label required">专馆形态</label>
                    <el-form-item prop="online_offline" :rules="[{required:false}]">
                        <el-select v-model="model.online_offline" placeholder="选择形态">
                            <el-option label="线下" value="线下"></el-option>
                            <el-option label="线上" value="线上"></el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6" v-if="false">
                    <label class="form-label required">经营主体</label>
                    <el-form-item prop="company_name" :rules="[{required:false}]">
                        <el-input v-model="model.company_name" placeholder="填写公司名称" required></el-input>
                    </el-form-item>
                    </div>
                    <div class="col-md-6">
                    <label class="form-label required">企业所在具体地址</label>
                    <el-form-item prop="website" :rules="[{required:true}]">
                        <el-input v-model="model.website" placeholder="请填写企业地址" required></el-input>
                    </el-form-item>
                    </div>
                    <div class="col-md-5">
                    <label class="form-label required">经营主体开户银行</label>
                    <el-form-item prop="bank_account" :rules="[{required:true}]">
                        <el-autocomplete v-model="model.bank_account" placeholder="填写开户银行"
                            :fetch-suggestions="queryBA">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                    <div class="col-md-5">
                    <label class="form-label required">支付方式</label>
                    <el-form-item prop="payments" :rules="[{required:true}]">
                        <el-autocomplete v-model="model.payments" placeholder="填写支付方式"
                            :fetch-suggestions="queryPM">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                    <div class="col-md-6">
                    <label class="form-label">备注</label>
                    <el-form-item>
                        <el-input v-model="model.remark"></el-input>
                    </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md" v-if="false">
                    <label class="form-label required">收银系统厂商</label>
                    <el-form-item prop="cashier_producer" :rules="[{required:false}]">
                        <el-autocomplete v-model="model.cashier_producer" placeholder="填写收银系统厂商信息"
                            :fetch-suggestions="queryCP">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                    
                </div>
                <hr class="mt-2 mb-3" />
                <div class="row" >
                    <div class="col-md-3" v-if="false">
                    <label class="form-label required">具备数据对接能力</label>
                    <el-form-item prop="api_cap" :rules="[{required:false}]">
                        <el-select v-model="model.api_cap" placeholder="请选择">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="线上"></el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                    <div class="col-md-3" v-if="false">
                    <label class="form-label required">是否已对接数据</label>
                    <el-form-item prop="api_en" :rules="[{required:false}]">
                        <el-select v-model="model.api_en" placeholder="请选择">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                    
                </div>
                
                <div class="text-center">
                    <button type="button" class="btn btn-primary mt-4 ls-3" @click="submit">保存</button>
                    <button type="button" class="btn btn-secondary mt-4 ls-3" @click="cancel">取消</button>
                    <button type="button" class="btn btn-secondary mt-4 ls-3" @click="del" v-show="delshow">删除</button>
                </div>
            </el-form>
        </div>
      </div>
    </div>
</template>

<script>
//import { Form, FormItem, Autocomplete} from 'element-ui';
import utils from '@/utils'
export default {
    // components: {
    //     [Form.name]: Form,
    //     [FormItem.name]: FormItem,
    //     [Autocomplete.name]: Autocomplete
    // },
    props: {
        modeldata: {
            type: Object,
            description: "the model object to update",
            default: null
        },
        list: {
            type: Array,
            description: "the saved models",
            default: null
        },
        saved: {
            type: Boolean,
            description: "indicating data saved",
            default: false
        },
        cancelled: {
            type: Boolean,
            description: "indicating wether editing is cancelled",
            default: false
        }
    },
    data() {
      return {
        tmp_modeldata: this.modeldata,
        tmp_saved: this.saved,
        tmp_cancelled: this.cancelled,
        loading: false,
        delshow: true,
        model: {
            id: null,
            district: '',
            store_name: '', 
            company_name: '', 
            gov_ent: '', 
            online_offline: '线下', 
            website: '',  
            cashier_producer: 'default', 
            bank_account: '', 
            payments: '', 
            api_cap: '否', 
            api_en: '否', 
            remark: ''
        },
        options:{
            districts: ["广州市/白云区","广州市/越秀区","广州市/天河区","广州市/海珠区","广州市/荔湾区","广州市/番禺区","广州市/南沙区","广州市/黄埔区","广州市/花都区","广州市/从化区","广州市/增城区"]
        }
      }
    },
    computed: {
        profiles() {
            return this.$store.state.profiles.profiles || {}
        }
    },
    watch: {
        modeldata(v) {
            // if "modeldata" changed, means another Counter is passed to be edit
            // we need to refresh the form config
            this.model = JSON.parse(JSON.stringify(v));
            this.tmp_cancelled = false;
            this.tmp_saved = false;
        },
        tmp_modeldata(v) {
            this.$emit("update:modeldata", v);
        },
        tmp_saved(v) {
            // telling parents component the data is saved
            this.$emit("update:saved", v);
        },
        tmp_cancelled(v) {
            // telling parents component user cancelled editing
            this.$emit("update:cancelled", v);
        }
    },
    methods: {
        query(field, query, callback) {
            var res = query ? this.list.filter((x)=>{ return x[field].indexOf(query) >= 0 }) : this.list;
            res = res.map((x)=>{ return { value: x[field] } })
            callback(utils.distinct(res))
        },
        queryCP(query, cb) { this.query('cashier_producer', query, cb); },
        queryBA(query, cb) { this.query('bank_account', query, cb); },
        queryPM(query, cb) { this.query('payments', query, cb); },
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.save();
                } else {
                    return false;
                }
            });
        },
        save() {
            this.loading = true;
            this.axios({
                method: this.model.id ? 'put' : 'post', // put for update, post for create
                url: this.model.id ? 'stores/' + this.model.id : 'stores',
                data: this.model
            }).then((res) => {
                this.loading = false;
                if (res.data.errcode == 0) {
                    this.tmp_saved = true;
                }
                else {
                    this.$notifyErr('保存失败', res.data.errmsg);
                }
            }).catch((err) => {
                if(err.response) {
                    if(err.response.data.error) this.$notifyErr('保存失败', err.response.data.error.message);
                    else this.$notifyErr('保存失败', err.response.data[0].message);
                }
                else this.$notifyErr('保存失败', 'Failed: ' + err.message);
                this.loading = false;
            })
        },
        cancel() {
            this.tmp_cancelled = true
        },
        del() {
            this.loading = true;
            this.axios({
                method: 'delete',
                url: this.model.id ? 'stores/' + this.model.id : 'stores',
                data: this.model
            }).then((res) => {
                this.loading = false;
                if (res.data.errcode == 0) {
                    this.tmp_saved = true;
                }
                else {
                    this.$notifyErr('删除失败', res.data.errmsg);
                }
            }).catch((err) => {
                if(err.response) {
                    if(err.response.data.error) this.$notifyErr('删除失败', err.response.data.error.message);
                    else this.$notifyErr('删除失败', err.response.data[0].message);
                }
                else this.$notifyErr('删除失败', 'Failed: ' + err.message);
                this.loading = false;
            })
        }
    },
    mounted() {
        this.tmp_cancelled = false;
        this.tmp_saved = false;
        if(this.tmp_modeldata)
            this.model = JSON.parse(JSON.stringify(this.tmp_modeldata));
        else
            this.model.company_name = this.profiles.company;
    }
};
</script>
<style scoped>
    .el-select, .el-autocomplete {
        width: 100%;
    }
    label.required::before {
        content: '*';
        color: red;
        position: absolute;
        top: 0;
        left: 6px;
        font-weight: 300;
        font-size: smaller;
    }
</style>